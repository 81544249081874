import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Container, Typography } from "@material-ui/core"

import { getRelatedArticles } from "../utils/article"

import SEO from "../components/seo"
import ArticleListing from "../components/article-listing"
import MarkdownFormatter, { H3 } from "../components/markdown-formatter"

const ArticleContainer = styled("article")`
  max-width: 70ch;
  margin: 0 auto;

  iframe {
    border: none;
  }

  .gatsby-resp-iframe-wrapper {
    margin-bottom: 16px;
  }
`

const Title = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  font-family: ${({ theme }) => theme.typography.secondary};
`

const ArticleTemplate = (props) => {
  const { Article, Category, ArticlesByCategory } = props.data
  const {
    nodes: [{ frontmatter: ArticleFrontmatter, htmlAst: articleContent }],
  } = Article
  const relatedArticles = getRelatedArticles(
    ArticleFrontmatter.related_articles,
    props.pageContext.articleData
  )

  return (
    <Container maxWidth="md">
      <SEO title={ArticleFrontmatter.title} />
      <ArticleContainer>
        <Title component="h1" variant="h5">
          {ArticleFrontmatter.title}
        </Title>
        <MarkdownFormatter content={articleContent} />
      </ArticleContainer>
      <ArticleListing title="Susiję straipsniai" articles={relatedArticles} />
      <H3>Straipsniai kategorijoje</H3>
      <ArticleListing
        title={Category.nodes[0].frontmatter.title}
        description={Category.nodes[0].frontmatter.description}
        articles={ArticlesByCategory.nodes}
      />
    </Container>
  )
}

ArticleTemplate.propTypes = {
  data: PropTypes.object,
}

export default ArticleTemplate

export const pageQuery = graphql`
  query($slug: String!, $categorySlug: String!) {
    Article: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: $slug } } }
    ) {
      nodes {
        frontmatter {
          title
          slug
          category
          related_articles
          thumbnail {
            childImageSharp {
              fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        htmlAst
      }
    }
    ArticlesByCategory: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: $categorySlug } } }
    ) {
      nodes {
        frontmatter {
          slug
          title
          category
          thumbnail {
            childImageSharp {
              fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    Category: allMarkdownRemark(
      filter: {
        fields: { sourceName: { eq: "categories" } }
        frontmatter: { slug: { eq: $categorySlug } }
      }
    ) {
      nodes {
        frontmatter {
          title
          description
        }
      }
    }
  }
`
