import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Typography, Link } from "@material-ui/core"
import rehypeReact from "rehype-react"
import { rgba } from "polished"

const Heading = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.secondary};
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

const Paragraph = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

const ListItem = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`

const Blockquote = styled("blockquote")`
  ${({ theme }) => `
    margin: 16px 0;
    padding: 16px;
    border-left: 10px solid ${theme.palette.primary.light};
    background-color: ${rgba(theme.palette.primary.light, 0.2)};

    ${theme.breakpoints.up("sm")} {
      margin: 32px;
    }
    
    p {
      margin: 0;

      ${theme.breakpoints.up("sm")} {
        font-size: 1.5rem;
        line-height: 1.334;
      }
    }
  `}
`

const BLOCKQUOTE = ({ children }) => <Blockquote>{children}</Blockquote>

const H1 = ({ children }) => (
  <Heading component="h1" variant="h5">
    {children}
  </Heading>
)

const H2 = ({ children }) => (
  <Heading component="h2" variant="h5">
    {children}
  </Heading>
)
const H3 = ({ children }) => (
  <Heading component="h3" variant="subtitle1">
    {children}
  </Heading>
)

const P = ({ children }) => (
  <Paragraph component="p" variant="body1">
    {children}
  </Paragraph>
)

const LI = ({ children }) => (
  <ListItem component="li" variant="body1">
    {children}
  </ListItem>
)

const renderAst = new rehypeReact({
  createElement: React.createElement,
  Fragment: React.Fragment,
  components: {
    h1: H1,
    h2: H2,
    h3: H3,
    p: P,
    li: LI,
    blockquote: BLOCKQUOTE,
    a: Link,
  },
}).Compiler

const MarkdownFormatter = ({ content }) => {
  return <React.Fragment>{renderAst(content)}</React.Fragment>
}

MarkdownFormatter.propTypes = {
  content: PropTypes.object,
}

export { MarkdownFormatter as default, Heading, LI, H2, H3 }
